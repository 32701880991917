


import React from 'react'
import { Outlet } from 'react-router-dom'

export default function TenantIndex() {
    return (
      <div>
          <Outlet />
      </div>
    );
}
