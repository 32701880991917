

import React from 'react'
import { FcBinoculars, FcBusinessman, FcConferenceCall, FcHome } from 'react-icons/fc';
import {MdAddCircle} from "react-icons/md"
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addIntroAtom, addRecordAtom, landlordAtom, modalAtom, tenantAtom } from '../../_state/modal';

export default function Summary() {


  const openModal = useSetRecoilState(modalAtom)
  const [intro, showIntro] = useRecoilState(addRecordAtom)
  const tenant = useSetRecoilState(tenantAtom)
  const landlord = useSetRecoilState(landlordAtom)

  const showModal = () => {
    
    openModal(true);
    showIntro(true);
    tenant(false)
    landlord(false)
    
  }


    return (
      <div>
        <div className="flex space-x-52 items-start">
          <div className="">
            <h1 className="text-2xl font-bold">Ikota Villa Estate</h1>
            <p>Beside Mega Chicken Restaurant, Ikota</p>
          </div>
          <button className="py-2.5 min-w-[200px] bg-blue-600 flex justify-center items-center space-x-2 rounded-md text-white"
          onClick={showModal}
          >
            <span>
              <MdAddCircle className="text-2xl" />
            </span>
            Add Landlord
          </button>
        </div>

        <div className="mt-10">
          <h1 className="text-slate-500 my-2">Summary</h1>
          <div className="flex space-x-3">
            {testData.map((item, i) => (
              <Link key={i} to={item.link}>
                <div key={i} className="bg-indigo-50 p-2.5 rounded-md drop-shadow">
                  <div className="flex justify-between space-x-11">
                    <div>
                      <p className="text-base font-light">{item.name}</p>
                      <h1 className="text-3xl font-bold">{item.value}</h1>
                    </div>
                    <div>{item.icon}</div>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <h1 className="text-slate-500 my-2 mt-10">Actions</h1>
          <div className="flex flex-col gap-y-2.5">
            {sampleData.map((_, i) => (
              <Link to={_.link} key={`i-${i}`}><div
                key={i}
                className="flex space-x-3 items-center group cursor-pointer w-1/4"
              >
                <div className="group-hover:bg-[#BBDCEE] rounded-md p-2 flex justify-center items-center">
                  {_.icon}
                </div>
                <p className="text-lg font-bold">{_.item}</p>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
}


const sampleData = [
  {
    item: "View landlords",
    icon: <FcBusinessman className="text-3xl" />,
    link: "landlord"
  },
  {
    item: "View Tenants",
    icon: <FcConferenceCall className="text-3xl" />,
    link: "tenant"
  },
];


const testData = [
    {
        name: "Total Residents",
        value: 9000,
        icon: <FcConferenceCall className="text-6xl" />,
        link: "tenant"
  },
  {
        name: "Number of Landlords",
        value: 500,
        icon: <FcBusinessman className="text-6xl" />,
        link: "landlord"
    },
    {
        name: "Total Number of Houses",
        value: 4500,
        icon: <FcHome className="text-6xl" />,
        link: "house"
  },
    
]