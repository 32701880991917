



import React from 'react'
import { MdArrowBack, MdCheckCircleOutline, MdSend } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { modalAtom } from '../../../_state/modal';
import ModalWrapper from '../../dashboard/child/ModalWrapper';

export default function EmergencyIndex() {
    
    const navigate = useNavigate()

    const openModal = useSetRecoilState(modalAtom)
    
    

    function submitEmergency(e) {
        openModal(true)
    }

    function handleGoBack() {
      navigate(-1);
    }

    function goBackHome() {
      navigate("/user");
      openModal(false);
    }


    return (
      <div>
        <ModalWrapper>
          <div className="flex flex-col items-center">
            <MdCheckCircleOutline className="text-center text-6xl text-green-600 mb-2" />
            <p className="text-center text-xl font-light">
              Your emergency has been sent successfully!
            </p>
            <button
              className="w-[200px] py-2 bg-blue-700 text-white rounded-full mt-3.5"
              onClick={goBackHome}
            >
              Go home
            </button>
          </div>
        </ModalWrapper>
        <div className="mx-5 my-10">
          <div
            className="mb-5 flex items-center space-x-2"
            onClick={handleGoBack}
          >
            <MdArrowBack />
            <p>Go back</p>
          </div>
          <div className="mt-10">
            <h1 className="text-2xl text-center font-bold my-2.5">
              What happened?
            </h1>
            <div className="">
              <textarea
                rows={4}
                value=""
                name="description"
                className="w-full border-4 rounded-md"
              />
            </div>
          </div>
          <div className="mx-5 flex justify-center">
            <button
              className="w-20 h-20 py-2 bg-red-700 text-white rounded-full mt-3 flex justify-center items-center"
              onClick={submitEmergency}
            >
              <MdSend className="text-2xl" />
            </button>
          </div>
        </div>
      </div>
    );
}
