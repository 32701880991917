

import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { modalAtom } from '../../../_state/modal';
import ModalWrapper from '../../dashboard/child/ModalWrapper';
import { usePaystackPayment } from 'react-paystack'

export default function PaymentSummary() {


  const location = useLocation()
  const navigate = useNavigate();
  const openModal = useSetRecoilState(modalAtom);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [summary, setSummary] = useState(location.state)

  function handleGoBack() {
    navigate(-1);
  }

  // function handleSubmitLogin(e) {
  //   e.preventDefault();
  //   console.log("move");
  //   openModal(true);
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   setTimeout(() => {
  //     setSuccess(true);
  //     console.log("Sending request");
  //   }, 2000);

  // }

  function goBackHome() {
    navigate("/user");
    openModal(false)
  }

  console.log(location.state)

  // ************************ Payment here
  const config = {
    reference: (new Date()).getTime().toString(),
    email: `${summary.name}@example.com`,
    amount: summary.amount,
    publicKey: 'pk_test_dd48938493bc54cbeddc120fb801a9642a65b420',
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    navigate("/user")
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
        <button
          className="w-full py-2 bg-blue-700 text-white rounded-full mt-20"
          onClick={() => {
            initializePayment(onSuccess, onClose)
          }}
        >
          Make Payment
        </button>
      </div>
    );
  };


  return (
    <div>
      <ModalWrapper>
        {loading ? (
          <p>Processing</p>
        ) : success ? (
          <div>
            <p className="text-center font-medium">
              Guest Information Created
            </p>
            <button
              className="w-[200px] py-2 bg-blue-700 text-white rounded-full mt-3.5"
              onClick={goBackHome}
            >
              Go home
            </button>
          </div>
        ) : null}
      </ModalWrapper>
      <div className="mx-5 my-10">
        {/* Route back */}
        <div
          className="mb-5 flex items-center space-x-2 cursor-pointer"
          onClick={handleGoBack}
        >
          <MdArrowBack />
          <p>Go back</p>
        </div>
        <div className="flex flex-col space-y-3.5 bg-slate-50 rounded-lg p-3">
          <h1 className="text-2xl font-semibold">Payment Summary</h1>
          <div>
            <h1 className="font-light">Due type</h1>
            <p className="font-bold">{summary.due}</p>
          </div>
          <div>
            <h1 className="font-light">Amount</h1>
            <p className="font-bold">{summary.amount}</p>
          </div>
          <div>
            <h1 className="font-light">Billed to:</h1>
            <p className="font-bold">{summary.name}</p>
          </div>
          <div className="mx-5">
            <PaystackHookExample />
          </div>
        </div>
      </div>
    </div>
  );
}
