

import { useQuery } from "@tanstack/react-query";

const url = process.env.REACT_APP_BASE_URL;

const fetchTenantList = () => {
  const res = fetch(`${url}/tenant/get/?id=1`).then((data) => data.json());
  return res;

  // .then((data) => console.log(data));
};

export default function () {
  return useQuery(["tenantList"], fetchTenantList);
}