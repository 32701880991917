


import React, { useState } from 'react'
import { useRecoilValue } from 'recoil';
import useAddLandlord from '../../../_query/landlord/useAddLandlord';
import { userAtom } from '../../../_state/user';

export default function AddLandlord() {
   
    const baseUrl = process.env.REACT_APP_BASE_URL;
   const user = useRecoilValue(userAtom);
   console.log(user);

   const addLandlord = useAddLandlord();

   const [detail, setDetail] = useState({
     name: "",
     email: "",
     estate_code: "",
     password: "",
     house_code: "",
   });

   function inputChangeHandler(e) {
     const { name, value } = e.target;
     //   console.log(name, value);
     setDetail({ ...detail, [name]: value });
   }

   const handleAddTenant = (e) => {
     const { name, email, estate_code, password, house_code } = detail;
     e.preventDefault();
     console.log("Attempting request");
     addLandlord.mutateAsync({
       name: name,
       email: email,
       estate_code: estate_code,
       password: password,
       house_code: house_code,
     });
     
   };

   // function handleAddTenant(e) {
   //     e.preventDefault()
   //     try {

   //         const {name, email, estate_code, password, house_code} = detail
   //         addTenant.mutateAsync({
   // name: name,
   // email: email,
   // estate_code: estate_code,
   // password: password,
   // house_code: house_code,
   //         });

   //     } catch (err) {
   //         console.log(err)
   //     }
   // }

   return (
       <div>
        
           <form className="flex flex-col space-y-3 items-center justify-center">
               <h1 className="text-2xl font-bold text-left">Add new Landlord</h1>
         <input
           className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
           placeholder="Full Name"
           type="text"
           name="name"
           value={detail.name}
           onChange={inputChangeHandler}
         />
         <input
           className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
           placeholder="Email/Username"
           type="email"
           name="email"
           value={detail.email}
           onChange={inputChangeHandler}
         />
         <input
           className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
           placeholder="password"
           type="password"
           name="password"
           value={detail.password}
           onChange={inputChangeHandler}
         />

         {/* <Link to="/dashboard"> */}
         <button
           className="bg-blue-600 rounded-md p-2 text-white w-[350px]"
           onClick={handleAddTenant}
         >
           Add New Tenant
         </button>
         {/* </Link> */}
       </form>
     </div>
   );
}
