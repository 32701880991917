import { atom } from "recoil";

const isServer = typeof window === "undefined";

export const authAtom = atom({
  key: "auth",
  //get initial state from local storage to enable user to stay logged in
  default: !isServer ? JSON.parse(localStorage.getItem("homeclan-user")) : "null",
});


export const loadingAtom = atom({
  key: 'login-loading',
  default: false
})