



import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useSingleLandlord from '../../../_query/landlord/useSingleLandlord';

export default function SingleLandlord(props) {


    const navigate = useNavigate()

    const routeData = useLocation()
    const {name, road, house, id} = routeData.state
    // console.log(name, road, house)

    const handleGoBack = () => {
        console.log("i")
        navigate(-1)
    }

  const { data, error, isLoading } = useSingleLandlord()
  
  if (isLoading) {
    console.log("Loading data")
  }

  if (error) {
    console.log(error)
  }

  if (data) {
    console.log("The data single landlord", data) 
  }
  
  
    return (
      <div>
        <button onClick={handleGoBack}>
          <p>{`< Go back`}</p>
        </button>

        <div className="mt-10 font-bold text-lg flex justify-between items-center">
          <h1>Landlord details</h1>
          {data !== undefined && data[0].house_code}
        </div>
        <hr />

        <h1 className="text-3xl font-bold">{data !== undefined && data[0].detail.name}</h1>

        <div className="mt-16 ">
          <p className="font-light">House(s)</p>
          <p className="hover:underline font-bold">
            Road {road}, House {house}
          </p>
        </div>

        <div className="mt-10">
          <p className="font-light">{data !== undefined && data[0].tenants.length} Tenant(s)</p>
          <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="py-3 px-3">
                    S/N
                  </th>
                  <th scope="col" className="py-3 px-3">
                    Name
                  </th>
                  <th scope="col" className="py-3 px-3">
                    ID
                  </th>
                  <th scope="col" className="py-3 px-6">
                    House
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data !== undefined && data[0].tenants.map((user, i) => {

                // console.log(user)
                  return (
                    <tr
                      className="bg-white border-b group"
                      // onClick={() => handleRowClick(i, user)}
                    >
                      <th
                        scope="row"
                        className="py-4 px-3 font-medium text-gray-900 whitespace-nowrap group-hover:bg-blue-600 group-hover:text-white"
                      >
                        {i + 1}
                      </th>
                      <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                        {user.name}
                      </td>
                      <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                        {user.user_id}
                      </td>
                      <td className="py-4 px-6 group-hover:bg-blue-600 group-hover:text-white">
                        <a
                          href="#"
                          className="font-medium text-blue-600 hover:text-white hover:underline group-hover:bg-blue-600 group-hover:text-white"
                        >
                          {user.house_code}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
        </div>
      </div>
    );
}



const testData = [
  "Iyabo Enahoro",
  "Micheal Adjli",
  "Nike Hassan",
  "AbdulMakeem Abiola",
];