import { useMutation, useQueryClient } from "@tanstack/react-query";

const url = process.env.REACT_APP_BASE_URL;

const addNewLandlord = ({ data }) => {
  const res = fetch(`${url}/landlord/create`, {
    method: "POST",
    // headers: {
    //   "Content-type": "text/plan",
    // },
    body: data,
  });
  return res;
};

export default function useAddLandlord() {
  const queryClient = useQueryClient();

  return useMutation(addNewLandlord, {
    onError: (error) => {
      console.log(error.response);
    },
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("singleTenant");
    },
  });
}
