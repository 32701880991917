
import { Route, Routes } from "react-router-dom"
import Login from "../auth/Login";
import DashboardIndex from "../dashboard/DashboardIndex";
import LandlordIndex from "../dashboard/landlord/LandlordIndex";
import Summary from "../dashboard/Summary";
import HomeIndex from "../home/HomeIndex"
import TenantIndex from "../dashboard/tenant/TenantIndex";
import HouseIndex from "../dashboard/house/HouseIndex";
import LandlordList from "../dashboard/landlord/LandlordList";
import SingleLandlord from "../dashboard/landlord/SingleLandlord";
import HouseList from "../dashboard/house/HouseList";
import SingleHouse from "../dashboard/house/SingleHouse";
import TenantList from "../dashboard/tenant/TenantList";
import SingleTenant from "../dashboard/tenant/SingleTenant";
import React from "react";
import TransactionIndex from "../dashboard/transaction/TransactionIndex";
import UserHome from "../user/home/UserHome";
import Guest from "../user/guest/Guest";
import GuestSummary from "../user/guest/GuestSummary";
import PaymentIndex from "../user/payment/PaymentIndex";
import PaymentSummary from "../user/payment/PaymentSummary";
import EmergencyIndex from "../user/emergency/EmergencyIndex";
import UserIndex from "../user/home/UserIndex";
import ProfileIndex from "../user/profile/ProfileIndex";



export default function MainRoute() {
    return (
      <div>
        <Routes>
          {/* Home route */}
          <Route path="/" element={<HomeIndex />} />

          {/* Login */}
          <Route path="/login" element={<Login />} />

          {/* Dashboard route */}
          <Route path="dashboard" element={<DashboardIndex />}>
            <Route path="/dashboard/" element={<Summary />} />
            <Route path="house" element={<HouseIndex />}>
              <Route path="/dashboard/house/" element={<HouseList />} />
              <Route path=":id" element={<SingleHouse />} />
            </Route>
            <Route path="landlord" element={<LandlordIndex />}>
              <Route path="/dashboard/landlord/" element={<LandlordList />} />
              <Route path=":id" element={<SingleLandlord />} />
            </Route>
            <Route path="tenant" element={<TenantIndex />}>
              <Route path="/dashboard/tenant/" element={<TenantList />} />
              <Route path=":id" element={<SingleTenant />} />
            </Route>

            <Route path="transaction" element={<TransactionIndex />}></Route>
          </Route>
          {/* User routes */}
          <Route path="user" element={<UserIndex />}>
            <Route index element={<UserHome />} />
            <Route path="profile" element={<ProfileIndex />} />
          </Route>
          <Route path="/user/guest" element={<Guest />} />
          <Route path="/user/guest/summary" element={<GuestSummary />} />
          {/* Payment */}
          <Route path="/user/payment" element={<PaymentIndex />} />
          <Route path="/user/payment/summary" element={<PaymentSummary />} />

          {/* Emergency */}
          <Route path="/user/emergency" element={<EmergencyIndex />} />
        </Routes>
      </div>
    );
}