



import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalAtom } from '../../../_state/modal';
import ModalWrapper from '../../dashboard/child/ModalWrapper';


export default function GuestSummary() {

  const navigate = useNavigate()
  const openModal = useSetRecoilState(modalAtom)
  const location = useLocation()
  console.log(location.state)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  function handleSubmitLogin(e) {
    e.preventDefault();
    console.log("move")
    openModal(true)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
    setTimeout(() => {
      setSuccess(true)
      console.log('Sending request')
    }, 2000);

  }

  function handleGoBack() {
    navigate(-1)
  }

  function goBackHome() {
    navigate("/user")
  }

    return (
      <div>
        <ModalWrapper>
          <div className="flex justify-center items-center m-1 p-1">
            {/* <p className="text-center">Processing guest information</p> */}
            {loading && <p className="text-green-400">Processing</p>}
            {success && (
              <div>
                <p className="text-center font-medium">
                  Guest Information Created
                </p>
                <button
                  className="w-[200px] py-2 bg-blue-700 text-white rounded-full mt-3.5"
                  onClick={goBackHome}
                >
                  Go home
                </button>
              </div>
            )}
          </div>
        </ModalWrapper>

        {/* Route back */}
        <div
          className="mb-5 flex items-center space-x-2 mx-7 mt-14"
          onClick={handleGoBack}
        >
          <MdArrowBack />
          <p>Go back</p>
        </div>
        <div className="mx-5 my-10 flex flex-col space-y-4 bg-slate-200 rounded-md px-3 py-5">
          <h1 className="text-2xl font-semibold">Guest Information Summary</h1>
          <div>
            <h1 className="font-light text-slate-500">Visitor Name</h1>
            <p className="font-bold">{location.state.name}</p>
          </div>
          <div>
            <h1 className="font-light text-slate-500">Purpose</h1>
            <p className="font-bold">{location.state.purpose}</p>
          </div>
          <div>
            <h1 className="font-light text-slate-500">Destination</h1>
            <p className="font-bold">House 12, Road 5, Ikota Estate Villa</p>
          </div>
        </div>
        <div className="mx-5">
          <button
            className="w-full py-2 bg-blue-700 text-white rounded-full mt-20"
            onClick={handleSubmitLogin}
          >
            Confirm
          </button>
        </div>
      </div>
    );
}
