

import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { FcBinoculars, FcBusinessman, FcConferenceCall, FcHome } from "react-icons/fc";

export default function SideBarMenu() {
 

  const location = useLocation()
  // console.log(location)



    return (
      <div className="flex flex-col space-y-2.5">
        <Link to="/dashboard/">
          <button className="bg-sky-50 text-lg target:bg-blue-600 target:text-white hover:bg-sky-400 px-2.5 w-40 py-1.5 rounded-md flex items-center gap-2 font-semibold">
            <span>
              <FcBinoculars className="text-2xl" />
            </span>{" "}
            Dashboard
          </button>
        </Link>
        <Link to="house">
          <button className="bg-sky-50 text-lg hover:bg-sky-400 px-2.5 w-40 py-1.5 rounded-md flex items-center gap-2 font-semibold">
            <span>
              <FcHome className="text-2xl" />
            </span>{" "}
            House
          </button>
        </Link>
        <Link to="landlord">
          <button className="bg-sky-50 text-lg hover:bg-sky-400 px-2.5 w-40 py-1.5 rounded-md flex items-center gap-2 font-semibold">
            <span>
              <FcBusinessman className="text-2xl" />
            </span>{" "}
            Landlord
          </button>
        </Link>
        <Link to="tenant">
          <button className="bg-sky-50 text-lg hover:bg-sky-400 px-2.5 w-40 py-1.5 rounded-md flex items-center gap-2 font-semibold">
            <span>
              <FcConferenceCall className="text-2xl" />
            </span>{" "}
            Tenant
          </button>
        </Link>
        <Link to="transaction">
          <button className="bg-sky-50 text-lg hover:bg-sky-400 px-2.5 w-40 py-1.5 rounded-md flex items-center gap-2 font-semibold">
            <span>
              <FcConferenceCall className="text-2xl" />
            </span>{" "}
            Transaction
          </button>
        </Link>
      </div>
    );
}
