import React, { useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function GuestForm() {
  const navigate = useNavigate();

  const [detail, setDetail] = useState({
    name: "",
    purpose: "",
  });


  function handleGoBack() {
    navigate(-1)
  }
  function handleInputChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setDetail({ ...detail, [name]: value });
  }

  function handleSubmitLogin(e) {
    const { name, purpose } = detail;
    e.preventDefault();
    if (name && purpose !== "") {
      navigate("/user/guest/summary", {
        state: {
          name: name,
          purpose: purpose,
        },
      });
    } else {
      return;
    }
  }

  return (
    <div className="mx-5 my-10 overflow-hidden">
      <div
        className="mb-5 flex items-center space-x-2"
        onClick={handleGoBack}
      >
        <MdArrowBack />
        <p>Go back</p>
      </div>
      <h1 className="text-2xl font-bold mb-5">Enter guest Information</h1>
      <div className="block">
        <form>
          <label className="font-bold mb-5">Visitor Name</label>
          <input
            className="w-full py-2 px-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 my-1.5"
            placeholder="Olamide Ciroma"
            type="text"
            name="name"
            value={detail.name}
            onChange={handleInputChange}

          />
          <label className="font-bold mt-5">Purpose</label>
          <select
            name="purpose"
            value={detail.purpose}
            className="w-screen py-2 border-2 rounded-sm px-1 mt-1.5 overflow-x-hidden"
            onChange={handleInputChange}
          >
            <option value="none" disabled hidden>
              Select purpose of visit
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.text}>
                {option.text}
              </option>
            ))}
          </select>

          {/* <Link to="/dashboard"> */}
          <button
            className="w-[90vw] py-2 bg-blue-700 text-white rounded-full fixed inset-x-0 bottom-32 mx-5"
            onClick={handleSubmitLogin}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}

const options = [
  { value: "family", text: "Family Member" },
  { value: "business", text: "Business" },
  { value: "delivery", text: "Dispatch / Delivery" },
  { value: "worker", text: "Worker" },
];
