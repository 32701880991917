import { useQuery } from "@tanstack/react-query";



const fetchAllDue = (estate_id) => {
    const res = fetch(`${process.env.REACT_APP_BASE_URL}/due/get?estate_id=${estate_id}`).then(data => data.json())
    return res
}



export default function useGetEstateDue(estate_id) {

    return useQuery(["getAllDue", [estate_id]], () => fetchAllDue(estate_id))
} 