import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import './App.css';
import MainRoute from './components/route/MainRoute';
import React from "react";

const queryClient = new QueryClient()

function App() {
  return (
    <div className="">
      <QueryClientProvider client={queryClient}>
        <MainRoute />
      </QueryClientProvider>
    </div>
  );
}

export default App;
