

import React from 'react'
import { Outlet } from 'react-router-dom'

export default function HouseIndex() {
    return (
      <div>
        <div>
          <Outlet />
        </div>
      </div>
    );
}
