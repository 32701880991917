import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { addIntroAtom, addRecordAtom, landlordAtom, tenantAtom } from "../../../_state/modal";

export default function AddRecord() {

  const [tenant, setTenant] = useRecoilState(tenantAtom)
  const [intro, setIntro] = useRecoilState(addRecordAtom)
  const [landlord, setLandlord] = useRecoilState(landlordAtom)

  const showTenant = () => {
    
    if (tenant) {
      return
    }
    setIntro(false)
    setTenant(true)
  }

  const showLandlord = () => {
    if (landlord) {
      return
    }
    setIntro(false)
    setLandlord(true)
  }

  return (
    <div>
      <h1 className="text-center mb-10 text-3xl font-bold">
        Which record do you want to add?
      </h1>
      <div className="grid grid-cols-2 w-full gap-2">
        <button className="bg-blue-600 hover:to-blue-300 py-2.5 rounded-md flex justify-center items-center text-white" onClick={showTenant}>
          <h1 className="text-xl">Tenant</h1>
        </button>
        <button className="bg-blue-600 hover:to-blue-300 py-2.5 rounded-md flex justify-center items-center text-white" onClick={showLandlord}>
          <h1 className="text-xl">Landlord</h1>
        </button>
        {/* <div className="bg-blue-600 h-20"></div>
        <div className="bg-blue-600 h-20"></div> */}
      </div>
    </div>
  );
}

const toAdd = [
  // {
  //   name: "House",
  // },
  {
    name: "Landlord",
  },
  {
    name: "Tenant",
  },
];



{/* <button className="bg-blue-600 px-5 py-2 rounded-sm text-white">
  
</button>; */}