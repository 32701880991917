



import { useQuery } from "@tanstack/react-query";

const url = process.env.REACT_APP_BASE_URL;

const fetchSingleTenant = (id) => {
  const res = fetch(`${url}/tenant/get/?id=${id}`).then((data) => data.json());
  return res;

  // .then((data) => console.log(data));
};

export default function (id) {
  return useQuery(["singleTenant", [id]], () => fetchSingleTenant(id));
}