



import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil';
import { authAtom } from '../../_state/auth';
import { addRecordAtom, landlordAtom, tenantAtom } from '../../_state/modal';
import { userAtom } from '../../_state/user';
import AddRecord from './child/AddRecord';
import ModalWrapper from './child/ModalWrapper';
import AddLandlord from './landlord/AddLandlord';
import SideBarMenu from './SideBarMenu'
import AddTenant from './tenant/AddTenant';

export default function DashboardIndex() {


  const user = useRecoilValue(userAtom)
  const auth = useRecoilValue(authAtom)
  const addIntro = useRecoilValue(addRecordAtom)
  const tenantShow = useRecoilValue(tenantAtom)
  const landlordShow = useRecoilValue(landlordAtom)
  // console.log("user auth token", auth)
  // console.log("user atom value", user)

    return (
      <div>
        <div className="grid grid-cols-12 mx-5">
          <div className="col-span-2 "></div>
          <div className="col-span-10 flex items-start justify-between w-full px-10">
            <Link to="/">
              <img src="/homeclan logo.svg" className="mt-7" />
            </Link>
            <img src="/admin-homepage.png" className="w-80" />
          </div>
        </div>
        <div className="grid grid-cols-12 mx-5">
          <div className="col-span-2 p-10 min-h-[80vh] ">
            <div className="flex flex-col">
              <SideBarMenu />
            </div>
          </div>
          <div className="col-span-10 px-10">
            {/* general modal */}
            <ModalWrapper>
              {addIntro && <AddRecord />}
              {tenantShow && <AddTenant />}
              {landlordShow && <AddLandlord />}
            </ModalWrapper>
            
            <Outlet />
          </div>
        </div>
      </div>
    );
}
