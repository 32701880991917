



import React from 'react'

export default function TransactionIndex() {
    return (
        <div>
            <h1>Transaction Index</h1>
        </div>
    )
}
