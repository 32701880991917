import { useQuery } from "@tanstack/react-query";



const url = process.env.REACT_APP_BASE_URL;

const fetchHouseList = () => {
  const res = fetch(`${url}/house/get`).then(data => data.json())
//   console.log("calling data response", res)  
    return res
    
        // .then((data) => console.log(data));
};

export default function () {
    return useQuery(['houseList'], fetchHouseList)
}