


import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react'
import { useRecoilState } from 'recoil';
import { modalAtom } from '../../../_state/modal';

export default function ModalWrapper({children}) {
    
    const [open, setOpen] = useRecoilState(modalAtom);

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      // border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      borderRadius: "10px"
    };

    // function openModal() {
    //   setOpen(true);
    // }

    // function afterOpenModal() {
    //   // references are now sync'd and can be accessed.
    // }

    function handleCloseModal() {
      setOpen(false);
    }



    
    return (
      <div>
        {/* <Button onClick={handleCloseModal}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {children}
          </Box>
        </Modal>
      </div>
    );
}
