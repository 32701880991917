import { useQuery } from "@tanstack/react-query";




const url = process.env.REACT_APP_BASE_URL;


const fetchSingleHouse = () => {
    const res = fetch(`${url}/house/get/1`).then((data) => data.json());
    return res;
}


export default function () {
    return useQuery(['singleHouse'], fetchSingleHouse)
}