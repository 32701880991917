import { useRecoilState } from "recoil";
import { authAtom } from "../_state/auth";
// import { fetchError } from "../_state/error";

export function useFetcher() {
  const [auth, setAuth] = useRecoilState(authAtom);

  // const history = useRouter();
  // const [error, setError] = useRecoilState(fetchError);

  //Requester method
  function request(method) {
    return async (url, body) => {
      const requestOptions = {
        method,
        headers: authHeader(url),
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      try {
        const response = await fetch(url, requestOptions);
        return handleResponse(response);
      } catch (error) {
        return console.log(error);
      }
        // .catch((error) => setError(error));
    };
  }

  // Auth reader method

  function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    // const token = String(auth?.authToken);
    const token = ""
    // console.log(token)
    const isLoggedIn = token
    const isApiUrl = url.startsWith(process.env.API_URL)
    // console.log(isApiUrl)

    if (isLoggedIn) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  }

  // response handler
  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if ([401, 403].includes(response.status) && auth.authToken) {
          //Auto logout if 401 Unauthorized or 403 Forbidden response returned
          localStorage.removeItem("user");
          setAuth(null);
          // history.push("/account/login");s
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };
}
