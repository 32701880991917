


import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useSingleTenant from '../../../_query/tenant/useSingleTenant';

export default function SingleTenant() {

  const navigate = useNavigate()
  const { id } = useParams()
  console.log(id)
    const handleGoBack = () => {
      console.log("i");
      navigate(-1);
    };

    const {data, error, isLoading } = useSingleTenant()
    

    if (isLoading) {
        console.log("Loading")
    }
    if (error) {
        console.log(error)
    }

    if (data) {
        console.log("single tenant details", data)
    }

    return (
      <div>
        <button onClick={handleGoBack}>
          <p>{`< Go back`}</p>
        </button>

        <div className="mt-10 font-bold text-lg flex justify-between items-center">
          <h1>Tenant details</h1>
          {data !== undefined && data[0].house_code}
        </div>
        <hr />

        <h1 className="text-3xl font-bold mt-4">
          {data !== undefined && data[0].detail.name}
        </h1>

        <div className="mt-10">
          <p className="font-light">House Address</p>
          <p className="hover:underline font-bold">{data && data[0].address}</p>

          <p className="font-light">Email Address</p>
          <p className="hover:underline font-bold">
            {data && data[0].detail.email}
          </p>

          <p className="font-light">Phone Number</p>
          <p className="hover:underline font-bold">
            {data && data[0].detail.phone}
          </p>

          <hr className="mt-10" />
          <div className="bg-slate-100 p-5 rounded-md">
            <p className="font-light">Landlord Detail</p>
            <div className="grid grid-cols-2">
              <p>Name</p>
                        <p className="hover:underline font-bold">
                {data && data[0].landlord[0].name}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}
