import { atom } from "recoil";




export const modalAtom = atom({
    key: "modal",
    default: false
})

export const addRecordAtom = atom({
    key: "addRecordAtom",
    default: false
})


export const tenantAtom = atom({
    key: 'tenantAtom',
    default: false
})

export const landlordAtom = atom({
    key: 'landlordAtom',
    default: false
})


// Recive guest state

export const submitGuestLoading = atom({
    key: 'submitGuestAtom',
    default: false
})


// 