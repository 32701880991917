


import React from 'react'
import { Outlet } from 'react-router-dom'
import GuestForm from './GuestForm'

export default function Guest() {




    return (
        <div>

            <Outlet />
            <GuestForm />
        </div>
    )
}
