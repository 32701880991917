
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useFetcher } from "../_helpers/fetcher";
// import { history } from "../_helpers/history";
import { authAtom, loading, loadingAtom } from "../_state/auth";
import { userAtom } from "../_state/user";

export function useUserActions() {


  // const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetcherWrapper = useFetcher();
  const setAuth = useSetRecoilState(authAtom);
  const setUser = useSetRecoilState(userAtom);
  const setLoading = useSetRecoilState(loadingAtom)

  const navigate = useNavigate()
  // console.log(baseUrl)

  return {
    login,
    // signup,
    logout,
    getUser,
  };

  // login user

  async function login(username, password) {
    setLoading(true)
    const res = fetch(`${baseUrl}/users/login`, {
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((data) => data.json())
      .then((res) => {
        // Store user token in local storage
        console.log(res)
        localStorage.setItem("homeclan-user", JSON.stringify(res.user.token))
        localStorage.setItem("user", res.user.detail)
        // sessionStorage.setItem("homeclan-user", JSON.stringify(res.user.token))
        setUser(res.user.detail)
        setLoading(false)
        navigate("/user")
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
      })

    // // store user details and jwt in localstorage
    // localStorage.setItem("homeclan-token", JSON.stringify(user));
    // getUser();console.log(email, password)
  }

  // signup user
  // async function signup(name, email, password) {
  //   const user = await fetcherWrapper.post(`${baseUrl}/signup`, {
  //     name,
  //     email,
  //     password,
  //   });
  //   console.log(user);
  //   localStorage.setItem("homeclan-token", JSON.stringify(user));
  //   getUser();
  //   // route.push("/feed");
  // }

  // log user out
  function logout() {
    // remove user from local storage. set auth state to null and redirect the user to homepage
    localStorage.removeItem("homeclan-token");
    setAuth(null);
    // route.push("/");
  }

  function getUser() {
    // const token = JSON.parse(localStorage.getItem("user"))?.authToken;
    // console.log(token)
    // const user = fetch(`${baseUrl}/me`, {
    //     method: 'GET',
    //     headers: {
    //         "Content-Type": "applicaton/json",
    //         Authorization: `Bearer ${token}`
    //     }
    // })

    // return user

    // return fetcherWrapper.get(`${baseUrl}/me`).then(setUser);
  }
}
