

import React from 'react'
import { MdCheck, MdCheckCircle } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../_state/user';

export default function UserHome() {

  const userDetail = useRecoilValue(userAtom)

  return (
    <div className="pb-5">
      <div className="w-full bg-blue-600 h-48 rounded-b-xl px-5 py-5">
        <div className="flex justify-between">
          <div className="text-white">
            <h1 className="text-lg font-bold">{userDetail.name}</h1>
            <p className="font-light text-sm">House 15, Road 2</p>
            <button className="bg-green-600 px-2 py-1 rounded text-xs">
              Ikota Estate Villa
            </button>

            <div className="flex space-x-1 items-center mt-14">
              <MdCheckCircle className="text-green-300" />
              <p className="text-green-200 text-sm font-light">{userDetail.is_tenant === 1 ? "Resident" : userDetail.is_landlord === 1 && "Landlord" || ""}</p>
            </div>
          </div>
          {/* user profile picture */}
          <div>
            <img className="w-10 h-10 bg-white rounded-full" src="/avatar.png" />
          </div>
        </div>
      </div>

      {/* Announcement */}

      <div className="px-5 py-5 flex justify-between items-center drop-shadow-md rounded-md mt-5 mx-5 bg-white">
        <div className="w-4/6">
          <h1 className="font-bold leading-tight text-lg">
            New Estate Announcement
          </h1>
          <p className="font-light text-sm">Click to Read Announcement</p>
        </div>
        <img src="/announce.png" className="" />
      </div>

      {/* Actions */}
      <div className="m-5">
        <p className="text-sm font-light mb-1">Actions</p>
        <div className="grid grid-cols-3 gap-2.5">
          <Link to="guest">
            <div className="bg-white rounded flex flex-col items-center p-2.5 min-h-[150px] cursor-pointer">
              <img src="/door.png" className="my-2" />
              <h4 className="text-sm font-bold text-center leading-none mb-2">
                Receive Guest
              </h4>
              <p className="text-[10px] font-light text-center">
                Create access code for your visitors
              </p>
            </div>
          </Link>
          <Link to="payment">
            <div className="bg-white rounded flex flex-col items-center p-2.5 min-h-[150px]">
              <img src="/pay.png" className="my-2" />
              <h4 className="text-sm font-bold text-center leading-none mb-2">
                Pay Due
              </h4>
              <p className="text-[10px] font-light text-center">
                Easily pay all estate dues with less stress
              </p>
            </div>
          </Link>
          <Link to="/user/emergency">
            <div className="bg-white rounded flex flex-col items-center p-2.5 min-h-[150px]">
              <img src="/alarm.png" className="my-2" />
              <h4 className="text-sm font-bold text-center leading-none mb-2">
                Emergency
              </h4>
              <p className="text-[10px] font-light text-center">
                Send emergency notice to the estate management
              </p>
            </div>
          </Link>
        </div>
      </div>

      {/* <div className="mx-5 mb-5">
          <p className="text-sm font-light">Other actions</p>
          <div className="block space-y-2.5">
            <div className="flex justify-between rounded-md bg-blue-700 text-white p-2.5">
              <div>
                <h1 className="text font-bold leading-none">Contact Gate</h1>
                <p className="text-[10px] font-light">
                  Contact the estate security at the gate
                </p>
              </div>
              <img src="" />
            </div>
            <div className="flex justify-between rounded-md bg-green-700 text-white p-2.5">
              <div>
                <h1 className="text font-bold leading-none">Complaints</h1>
                <p className="text-[10px] font-light">
                  Have something to report?
                </p>
              </div>
              <img src="" />
            </div>
          </div>
        </div> */}
    </div>
  );
}
