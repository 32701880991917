




import React, { useState } from "react";
import { MdArrowBack, MdDoorFront } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useUserActions } from "../../_actions.js/user.actions";
import { authAtom, loadingAtom } from "../../_state/auth";

export default function Login() {

  const navigate = useNavigate()
  const location = useLocation()

  console.log("state", location.state)

  const [detail, setDetail] = useState({
    email: "",
    password: ""
  })

  const auth = useRecoilValue(authAtom)
  const userAction = useUserActions()
  const loading = useRecoilValue(loadingAtom)
  // console.log(auth)

  function inputChangeHandler(e) {
    const { name, value } = e.target
    // console.log(name, value)
    setDetail({ ...detail, [name]: value })
  }

  function handleSubmitLogin(e) {
    e.preventDefault()
    const { email, password } = detail
    userAction.login(email, password)
    // navigate("/user")
  }


  // route
  function handleGoBack() {
    navigate(-1);
  }


  return (
    <div>
      <div className="min-h-screen max-h-screen bg-sky-50">
        <div className="flex flex-col items-center">
          <div>
            <img src="/admin-homepage.png" />
          </div>

          <div className="flex flex-col items-center space-y-10">
            <img src="/homeclan logo.svg" />
            <h1 className="text-2xl font-bold">{location.state.name} Login</h1>
          </div>
          <div
            className="my-5 flex items-center space-x-2"
            onClick={handleGoBack}
          >
            <MdArrowBack />
            <p>Go back</p>
          </div>
          <div className="mt-10">
            <form className="flex flex-col space-y-3">
              <input
                className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 text-center"
                placeholder="Email/Username"
                type="email"
                name="email"
                value={detail.email}
                onChange={inputChangeHandler}
              />
              <input
                className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 text-center"
                placeholder="password"
                type="password"
                name="password"
                value={detail.password}
                onChange={inputChangeHandler}
              />

              {/* <Link to="/dashboard"> */}
              <button
                className={`w-[350px] py-2 ${location.state.name === "Tenant" ? "bg-sky-500" : "bg-blue-700"} text-white rounded-md`}
                onClick={handleSubmitLogin}
              >
                Login <span>{loading && <MdDoorFront />}</span>
              </button>
              {/* </Link> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
