import { useMutation, useQueryClient } from "@tanstack/react-query";



const url = process.env.REACT_APP_BASE_URL;

const addNewTenant = ({data}) => {
    const res = fetch(`${url}/tenant/create/`, {
        method: "POST",
        headers: {
            'Content-type': 'text/plan'
        },
      body: data
    });
    return res
}

export default function useAddTenant() {

    const queryClient = useQueryClient()

    return useMutation(addNewTenant, {
        onError: (error) => {
            console.log(error.response)
        },
        onSuccess: (data) => {
            console.log(data)
            queryClient.invalidateQueries("singleTenant");
        }
    })
}