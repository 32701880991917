



import { useMutation } from '@tanstack/react-query';
import { data } from 'autoprefixer';
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import useAddTenant from '../../../_query/tenant/useAddTenant'
import { userAtom } from '../../../_state/user';

export default function AddTenant() {

const baseUrl = process.env.REACT_APP_BASE_URL;
    const user = useRecoilValue(userAtom)
    console.log(user)

    const addTenant = useAddTenant()

    const [detail, setDetail] = useState({
      name: "",
      email: "",
      estate_code: "EST001",
      password: "",
      house_code: "BLD0001",
    });


    function inputChangeHandler(e) {
      const { name, value } = e.target;
    //   console.log(name, value);
      setDetail({ ...detail, [name]: value });
    }

  
  const handleAddTenant = e => {
    const { name, email, estate_code, password, house_code } = detail;
    e.preventDefault()
    console.log("Attempting request")
    addTenant.mutateAsync({
      name: name,
      email: email,
      estate_code: estate_code,
      password: password,
      house_code: house_code,
    })
    console.log(addTenant.variables)
    console.log(addTenant.status)
  }
  
    // function handleAddTenant(e) {
    //     e.preventDefault()
    //     try {
            
    //         const {name, email, estate_code, password, house_code} = detail
    //         addTenant.mutateAsync({
              // name: name,
              // email: email,
              // estate_code: estate_code,
              // password: password,
              // house_code: house_code,
    //         });
            
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    return (
      <div className="">
        <h1 className="text-2xl font-bold mb-5">Add New Tenant</h1>
        <form className="flex flex-col space-y-3 items-center justify-center">
          <input
            className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
            placeholder="Full Name"
            type="text"
            name="name"
            value={detail.name}
            onChange={inputChangeHandler}
          />
          <input
            className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
            placeholder="Email/Username"
            type="email"
            name="email"
            value={detail.email}
            onChange={inputChangeHandler}
          />
          <input
            className="w-[350px] py-2 px-2.5 bg-transparent border border-slate-400 rounded-md placeholder-slate-300"
            placeholder="password"
            type="password"
            name="password"
            value={detail.password}
            onChange={inputChangeHandler}
          />

          {/* <Link to="/dashboard"> */}
          <button
            className="bg-blue-600 rounded-md p-2 text-white w-[350px]"
            onClick={handleAddTenant}
          >
            Add New Tenant
          </button>
          {/* </Link> */}
        </form>
      </div>
    );
}
