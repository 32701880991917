

import React from 'react'
import { useState } from 'react';
import { MdArrowBack, MdArrowForward, MdChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useGetEstateDue from '../../../_query/due/useGetEstateDue';
import { userAtom } from '../../../_state/user';

export default function PaymentIndex() {

  const navigate = useNavigate()

  const userDetail = useRecoilValue(userAtom)
  console.log("Detail of the user", userDetail)

  const { data, isLoading, error } = useGetEstateDue("EST001")


  // State


  if (isLoading) {
    console.log("Loading")
  }

  if (error) {
    console.log("Due eerror", error)
  }

  if (data) {
    console.log("Data received", data)
  }


  function handleGoBack() {
    navigate(-1)
  }


  function goToDueInfo(amount, dueType) {
    navigate("/user/payment/summary", {
      state: {
        name: userDetail.name,
        amount: amount,
        due: dueType
      }
    })
  }

  return (
    <div>
      <div className="mx-5 my-10">
        <div
          className="mb-5 flex items-center space-x-2 cursor-pointer"
          onClick={handleGoBack}
        >
          <MdArrowBack />
          <p>Go back</p>
        </div>
        <h1 className="text-2xl font-semibold">Select the due to pay</h1>

        {isLoading ? <h1>Loading dues</h1> : (<div className="flex flex-col space-y-4 mt-10">
          {data && data.map((due, i) => (
            <div
              key={`due-${i}`}
              className="flex justify-between cursor-pointer"
              onClick={() => goToDueInfo(due.amount, due.name)}
            >
              <p className="font-semibold">{due.name}</p>
              <MdChevronRight className="text-slate-400" />
            </div>
          ))}
        </div>)}
      </div>
    </div>
  );
}


const dues = ["Estate Resident Due", "Security Due", "Enviromental Due", "Estate Annual Fee", "Worker Permit Due"]
