


import React from 'react'
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../_state/user';

export default function ProfileIndex() {

  const navigate = useNavigate()

  const userDetail = useRecoilValue(userAtom)
  function handleLogout() {
    navigate("/")
  }

  return (
    <div className="mx-5 my-10">
      <div className="flex flex-col items-center ">
        <div className="w-20 h-20 rounded-full relative">
          <img
            src="/avatar.png"
            className="bg-white rounded-full object-contain absolute inset-0"
          />
        </div>

        <div className="flex flex-col items-center mt-10">
          <h1 className="text-xl font-bold">{userDetail.name}</h1>
          <p className="font-light">House 15, Road 2</p>
          <button className="bg-green-600 text-white px-2 py-1 rounded text-xs mt-2.5">
            Ikota Estate Villa
          </button>
        </div>

        <div className="fixed bottom-32">
          <button
            className="w-[250px] py-2 bg-red-600 text-white rounded-full flex items-center justify-center space-x-3 font-bold"
            onClick={handleLogout}
          >
            <p>Logout</p> <MdLogout />
          </button>
        </div>
      </div>
    </div>
  );
}
