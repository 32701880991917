



import { useQuery } from "@tanstack/react-query";
// import { useFetcher } from "../_helpers/fetcher";

const url = process.env.REACT_APP_BASE_URL;

const fetchSingleLandlord = () => {
  const res = fetch(`${url}/landlord/get/1`).then((data) => data.json());
  //   console.log("calling data response", res)
  return res;

  // .then((data) => console.log(data));
};

export default function (estate_id) {
  return useQuery(["singleLandlord", [estate_id]], fetchSingleLandlord);
}
