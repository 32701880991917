



import React from 'react'
import { MdAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useTenantList from '../../../_query/tenant/useTenantList';

export default function TenantList() {

    const navigate = useNavigate()

    function handleRowClick(i, data) {



        console.log(i);
        navigate(`/dashboard/tenant/${i}`);
    }

    // data
    const { data, error, isLoading } = useTenantList()
    
    if (isLoading) {
        console.log("Loading data")
    }

    if (error) {
        console.log(error)
    }

    if (data) {
        console.log("tenant data", data[0].detail.user_id)
    }


        return (
            <div>
                <div>
                    <h1 className="text-3xl font-bold">Tenants</h1>
                    <p className="font-light text-xl text-slate-600">
                        View tenant information
                    </p>
                </div>

                <div className="flex justify-between items-center mt-5">
                    <p className="text-lg font-light">
                        Total tenants: (<span>300</span>)
                    </p>
                    <button className="bg-blue-600 text-white px-2.5 py-1.5 group rounded-md flex items-center font-bold">
                        <span>
                            <MdAddCircle className="text-3xl mr-1.5 group-hover:rotate-6" />
                        </span>
                        Add new Tenant
                    </button>
                </div>

                {/* Table of value */}
                <div className="">
                    <div className=" overflow-y-scroll relative shadow-md rounded-md mt-10 max-h-[420px]">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="py-3 px-3">
                                        S/N
                                    </th>
                                    <th scope="col" className="py-3 px-3">
                                        Name
                                    </th>
                                    <th scope="col" className="py-3 px-3">
                                        ID
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        House
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data !== undefined && data.map((user, i) => (
                                    <tr key={`tenant${i}`}
                                        className="bg-white border-b group"
                                        onClick={() => handleRowClick(user.detail.user_id, user)}
                                    >
                                        <th
                                            scope="row"
                                            className="py-4 px-3 font-medium text-gray-900 whitespace-nowrap group-hover:bg-blue-600 group-hover:text-white"
                                        >
                                            {i + 1}
                                        </th>
                                        <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                                            {user.detail.name}
                                        </td>
                                        <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                                            {user.id}
                                        </td>
                                        <td className="py-4 px-6 group-hover:bg-blue-600 group-hover:text-white">
                                            <a
                                                href="#"
                                                className="font-medium text-blue-600 hover:text-white hover:underline group-hover:bg-blue-600 group-hover:text-white"
                                            >
                                                {user.address}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }


    const min = 10000;
    const max = 99999;

    const testData = [
        
        {
            name: "Hassan Amir",
            road: 2,
            house: 14,
            id: Math.floor(Math.random() * (max - min + 1)) + min,
        },
        {
            name: "Chukwu Emke",
            road: 2,
            house: 14,
            id: Math.floor(Math.random() * (max - min + 1)) + min,
        }
    ]