


import React from 'react'
import { FcBusinessman, FcHome } from 'react-icons/fc'
import { Link, Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../../_state/user'

export default function UserIndex() {

  const userDetail = useRecoilValue(userAtom)

  // console.log("User details from state", userDetail)


  return (
    <div className="relative h-screen overflow-y-scroll overflow-x-hidden">
      <Outlet />
      <div className="fixed bottom-0 inset-x-0">
        <div className="flex justify-around items-center bg-slate-200 h-16">
          <Link to="/user">
            <div className="flex-col flex items-center">
              <FcHome className="text-2xl" />
              <small>Home</small>
            </div>
          </Link>

          <Link to="profile">
            <div className="flex-col flex items-center">
              <FcBusinessman className="text-2xl" />
              <small>Profile</small>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
