

import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeIndex() {


  

    return (
      <div className="min-h-screen max-h-screen bg-sky-50">
        <div className="flex flex-col items-center">
          <div>
            <img src="/admin-homepage.png" />
          </div>

          <div className="flex flex-col items-center space-y-10">
            <img src="/homeclan logo.svg" />
            <h1 className="text-2xl font-bold">Homeclan Estate Portal</h1>
          </div>

          <div className="mt-20 space-y-3.5 flex-col flex md:block w-full px-10">
            <Link to="login" state={link1}>
              <button className="md-w-[350px] w-full py-2 hover:bg-blue-700 hover:text-white text-white bg-sky-500 rounded-md">
                Tenant Login
              </button>
            </Link>
            {/* <Link to="login" state={link2}>
              <button className="md-w-[350px] w-full py-2 hover:bg-blue-700 hover:text-white bg-sky-100  text-black rounded-md">
                Admin Login
              </button>
            </Link> */}
          </div>

          <div className="flex flex-col items-center space-y-2 mt-20">
            <div className="flex text-sm text-slate-500 space-x-5">
              <p>Homepage</p>
              <p>About</p>
              <p>Contact Us</p>
            </div>
            <p>{new Date().getFullYear().toPrecision()}</p>
          </div>
        </div>
      </div>
    );
}


const link1 = {
  name: "Tenant"
}

const link2 = {
  name: "Admin"
}