



import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useSingleHouseDetails from '../../../_query/useSingleHouseDetails';

export default function SingleHouse() {
    
    const navigate = useNavigate();

    const routeData = useLocation();
    // const { name, road, house, id, tenants } = routeData.state;
    // console.log(name, road, house, id)
  // console.log(routeData)

    const handleGoBack = () => {
      
      navigate(-1);
    };

  
  // Get the data to display
  const { data, error } = useSingleHouseDetails();

  if (data) {
    console.log("Main data from single house",data);
  } else {
    console.log("Error from single house request", error);
  }

  // console.log(data[0]);
  
    return (
      <div>
        <button onClick={handleGoBack}>
          <p>{`< Go back`}</p>
        </button>

        {/* <div className="mt-10 font-bold text-lg">{data && data !==undefined && data.house_code}</div> */}
        <p className="hover:underline font-bold">
          {/* {data && data.estate_code} */}
        </p>
        <hr />

        {/*  */}

        <div className="mt-10 ">
          <p className="font-light">Landlord</p>
          <h1 className="text-3xl font-bold hover:underline hover:text-blue-600 cursor-pointer">
            {data && data.landlord[0].name}
          </h1>
          <p className="hover:underline font-bold"></p>
        </div>

        <div className="mt-10">
          <p className="font-light">{data && data.tenants.length} Tenant(s)</p>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="py-3 px-3">
                  S/N
                </th>
                <th scope="col" className="py-3 px-3">
                  Name
                </th>
                <th scope="col" className="py-3 px-3">
                  ID
                </th>
                <th scope="col" className="py-3 px-6">
                  House
                </th>
              </tr>
            </thead>
            <tbody>

              {console.log("Test data", data && data.tenants)}
              {data &&
                data.tenants.map((user, i) => {
                  // console.log(user)
                  return (
                    <tr
                      className="bg-white border-b group"
                      // onClick={() => handleRowClick(i, user)}
                    >
                      <th
                        scope="row"
                        className="py-4 px-3 font-medium text-gray-900 whitespace-nowrap group-hover:bg-blue-600 group-hover:text-white"
                      >
                        {i + 1}
                      </th>
                      <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                        {user.name}
                      </td>
                      <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                        {user.user_id}
                      </td>
                      <td className="py-4 px-6 group-hover:bg-blue-600 group-hover:text-white">
                        <a
                          href="#"
                          className="font-medium text-blue-600 hover:text-white hover:underline group-hover:bg-blue-600 group-hover:text-white"
                        >
                          {user.phone}
                        </a>
                      </td>
                    </tr>
                   );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
}


const testData = [
  "Iyabo Enahoro",
  "Micheal Adjli",
  "Nike Hassan",
  "AbdulMakeem Abiola",
];